import React, { useEffect, useState } from "react";
import CutleryIcon from "../../images/shoppingBag.png";
import moment from "moment";
import toast from "react-hot-toast";
import { Card, Spinner, Table } from "react-bootstrap";
import Pagination from "../common/Pagination";
import { getUserPurchasedOffersApi } from "../../services/User/UserService";
import UserTableSkeleton from "../components/UserTableSkeleton";
import Filters from "../components/Filters";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import UnlimitedIndicator from "../components/UnlimitedIndecator";

function UserPurchasedOffers(props) {
  const data = props?.location?.state;
  const [offersList, setOfferList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterType, setFilterType] = useState("all");

  const FilterOptions = [
    { label: "All", value: "all" },
    { label: "Purchased", value: "purchased" },
  ];

  const limit = 10;

  async function getOffersListing() {
    setLoader(true);
    try {
      const response = await getUserPurchasedOffersApi(
        data?.userId,
        data?.programId,
        currentPage,
        limit,
        filterType
      );
      setOfferList(response?.data?.data);
      const total = response.data.data.total;
      setPageCount(Math.ceil(total / limit));
    } catch (err) {
      toast.error(err?.response?.data?.data);
    } finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    getOffersListing();
  }, [data, currentPage, filterType]);
  console.log(data.userId);
  return (
    <>
      <>
        <div className="mb-3">
          <div className="page-titles">
            <h4>User Offers</h4>
            <ol className="breadcrumb">
              <li
                className="breadcrumb-item"
                onClick={() =>
                  props?.history.push({
                    pathname: "/user-details",
                    state: { id: data?.userId },
                  })
                }
              >
                User Details
              </li>{" "}
              <li className="breadcrumb-item active">
                <h3>User Offers</h3>
              </li>
            </ol>
          </div>
          <Filters
            type="tabs"
            setFilterType={setFilterType}
            filterType={filterType}
            FilterOption={FilterOptions}
          />
        </div>
        {loader ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" />
          </div>
        ) : (
          <Card>
            <Card.Body>
              <Table responsive>
                <thead style={{ color: "black" }}>
                  <tr>
                    <th>
                      <strong>title</strong>
                    </th>
                    <th>
                      <strong>Description</strong>
                    </th>
                    <th>
                      <strong>Type</strong>
                    </th>
                    <th>
                      <strong>points</strong>
                    </th>
                    <th className="text-center">
                      <strong>Tier</strong>
                    </th>
                    <th className="text-center">
                      <strong>Times per user</strong>
                    </th>

                    <th>
                      <strong>Valid from</strong>
                    </th>

                    <th>
                      <strong>Valid Till</strong>
                    </th>
                    <th>
                      <strong>Purchased Date</strong>
                    </th>
                  </tr>
                </thead>
                {loader ? (
                  <UserTableSkeleton count={8} />
                ) : (
                  <tbody style={{ maxHeight: "420px", overflow: "scroll" }}>
                    {offersList?.offers?.map((item, i) => (
                      <tr key={i}>
                        <td>{item?.offer?.title} </td>
                        <td>{item?.offer?.description} </td>
                        <td className="text-center">
                          {item?.offer?.pointNeeded}
                        </td>
                        <td className="text-center">
                          {filterType === "all"
                            ? item?.pointPrice
                            : item?.points}
                        </td>
                        <td className="text-center">{item?.offer?.tier}</td>
                        <td className="text-center">
                          {item?.oncePerUser > 1000000
                            ? "Unlimited"
                            : item?.oncePerUser}
                          <UnlimitedIndicator value={item?.oncePerUser} />
                        </td>

                        <td> {moment(item?.offer?.startDate).format("ll")}</td>
                        <td>{moment(item?.offer?.validDate).format("ll")}</td>
                        <td>{moment(item?.createdAt).format("ll")}</td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </Table>
              {offersList.total === 0 && !loader && (
                <div className="d-flex justify-content-center">
                  <h3>Sorry no data found!</h3>
                </div>
              )}
              {offersList.total !== 0 && (
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div className="dataTables_info">
                    Total Offers
                    <span
                      style={{
                        color: "#042e77",
                        border: "1px solid #042e77",
                        padding: "5px 15px",
                        borderRadius: "5px",
                        marginLeft: "5px",
                      }}
                    >
                      {offersList?.total}
                    </span>
                  </div>
                  <Pagination
                    pageCount={pageCount}
                    pageValue={currentPage}
                    setPage={setCurrentPage}
                  />
                </div>
              )}
            </Card.Body>
          </Card>
        )}
      </>
    </>
  );
}

export default UserPurchasedOffers;
