import React, { useEffect, useState } from "react";
import "./SpinWheelDetails.css";
import { Badge, Card, Spinner, Table } from "react-bootstrap";
import Pagination from "../common/Pagination";
import winIcon from "../../icons/medal.png";
import {
  getExportSpinHistoryFileApi,
  getWheelHistoryApi,
} from "../../services/Vendor Services/VendorService";
import { BsCoin } from "react-icons/bs";
import { GiBowTieRibbon } from "react-icons/gi";
import { FaTrophy } from "react-icons/fa";
import { HiMiniUsers } from "react-icons/hi2";
import { PiSpinnerFill } from "react-icons/pi";
import Lottie from "lottie-react";
import animationData from "../../images/winner.json";

import moment from "moment";

const DataCard = ({ title, data, Icon }) => (
  <div className="data-card">
    <div className="icon">
      <Icon />
    </div>
    <div className="details">
      <span className="title">{title}</span>
      <span className="percentage">{data}</span>
    </div>
  </div>
);
const WinnerCard = ({ title, data, Icon }) => (
  <div className="winner-card">
    <div className="winner-profile">
      <div className="d-flex w-100">
        <div className="icon">
          <img src={winIcon} alt="img" height={30} width={30} />
        </div>
        <div className="details">
          <h4 className="percentage">#1 {data.name}</h4>
          <h4 className="percentage">
            +{data?.countryCode} {data?.phoneNumber}
          </h4>
          <h4 className="percentage">{data?.email}</h4>
        </div>
      </div>
      <div className="animation-badge">
        <Lottie animationData={animationData} />
      </div>
    </div>
  </div>
);

export default function SpinWheelDetails({ location }) {
  const item = location.state;
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [fileUrl, setFileUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const limit = 10;

  function getTableData() {
    setLoader(true);
    getWheelHistoryApi(item.id, currentPage, limit)
      .then((response) => {
        setData(response?.data?.data);
        setLoader(false);
        const total = response.data.data.total;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((error) => {
        console.log(error, "error");
        setLoader(false);
      });
  }
  console.log(item);
  async function getExportFileUrl() {
    setIsLoading(true);
    try {
      const response = await getExportSpinHistoryFileApi(item.id);
      setFileUrl(response?.data?.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error, "error");
      setIsLoading(false);
    }
  }

  console.log(fileUrl);
  const downloadFile = () => {
    if (fileUrl) {
      setIsLoading(true);

      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", "SpinHistory.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, [item?.id, currentPage]);

  return (
    <div className="spin-wheel-details">
      <div className="page-titles">
        <h4>Wheel Contest Details</h4>
      </div>
      {item?.winner && (
        <WinnerCard data={item.winner} title={"Winner"} Icon={FaTrophy} />
      )}
      <Card className="app-header">
        <h1>{item?.name}</h1>
        <div className="d-flex justify-content-between">
          <div style={{ width: "40%" }}>
            <div className="d-flex justify-content-between align-items-center">
              <div className="mt-3">
                <span className="mr-2">Start Date</span>
                <p className="m-0"> {moment(item?.startTime).format("ll")}</p>
              </div>
              <div className="mt-2">
                <span className="mr-3">End Date</span>
                <p className="m-0">{moment(item?.endTime).format("ll")}</p>
              </div>
            </div>
            <div className="message">
              <span>Message :</span>
              <p className="">{item?.message}</p>
            </div>
          </div>
          <div
            className="d-flex flex-wrap justify-content-end"
            style={{ width: "60%" }}
          >
            <DataCard
              data={item.pointsRequired}
              Icon={BsCoin}
              title={"Required Points"}
            />

            <DataCard
              data={item.timeAllowPerUser}
              Icon={HiMiniUsers}
              title={"Allow Per User"}
            />
            <DataCard
              data={item.totalNumberSpin}
              Icon={PiSpinnerFill}
              title={"Total Spin"}
            />
            {item?.specifyLoyaltyTier?.length == 4 ? (
              <DataCard
                data={"All"}
                Icon={GiBowTieRibbon}
                title={"Loyalty Tier"}
              />
            ) : (
              <>
                <div className="data-card">
                  <div className="icon">
                    <GiBowTieRibbon />
                  </div>
                  <div className="details ">
                    <span className="title">Loyalty Tier</span>
                    <div className="d-flex">
                      {item?.specifyLoyaltyTier?.map((tier) => (
                        <span className="percentage">{tier},</span>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Card>
      <Card className="cards-section">
        <h3>Spin Wheel Prizes</h3>
        <div className="d-flex flex-wrap ">
          {item?.wheelPrizes?.map((spin, i) => (
            <div className="data-card ml-3" key={i}>
              <div className="icon">
                <i className="flaticon-381-help"></i>
              </div>
              <div className="details">
                <span className="title">{spin?.prize}</span>
                <span className="percentage">Quantity {spin?.quantity}</span>
              </div>
            </div>
          ))}
        </div>
      </Card>
      <main>
        <Card className="user-profile">
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-start pt-2">
              <img src={winIcon} alt="img" height={25} width={25} />
              <h3 className="p-0 ml-2">Spin by users</h3>
            </div>
            {fileUrl ? (
              <button className="btn btn-primary btn-sm" onClick={downloadFile}>
                {isLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Download file"
                )}
              </button>
            ) : (
              <button
                className="btn btn-primary btn-sm"
                onClick={getExportFileUrl}
              >
                {isLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Export file"
                )}
              </button>
            )}
          </div>
          {loader ? (
            <div className="d-flex justify-content-center align-items-center">
              <Spinner animation="border" />
            </div>
          ) : (
            <Table responsive>
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>name</strong>
                  </th>
                  <th>
                    <strong>contact</strong>
                  </th>
                  <th>
                    <strong>email</strong>
                  </th>
                  <th>
                    <strong>Prize</strong>
                  </th>
                </tr>
              </thead>

              <tbody style={{ maxHeight: "420px", overflow: "scroll" }}>
                {data?.wheels?.map((item, i) => (
                  <tr key={i}>
                    <td>{item.User.name}</td>

                    <td>{item?.User?.phoneNumber}</td>
                    <td>{item?.User.email} </td>
                    <td style={{ minWidth: "100px" }}>{item?.prize}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          {data?.total === 0 && !loader && (
            <div className="justify-content-center d-flex my-5 ">
              Sorry, Data Not Found!
            </div>
          )}

          <div className="d-flex justify-content-between align-items-center">
            <div className="dataTables_info">
              Total Spins
              <span
                style={{
                  color: "#042e77",
                  border: "1px solid #042e77",
                  padding: "5px 15px",
                  borderRadius: "5px",
                  marginLeft: "5px",
                }}
              >
                {data?.total}
              </span>
            </div>
            <Pagination
              pageCount={pageCount}
              pageValue={currentPage}
              setPage={setCurrentPage}
            />
          </div>
        </Card>
      </main>
    </div>
  );
}
