import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const UnlimitedIndicator = ({ value, threshold = 1000000 }) => {
  // Render the tooltip component
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      If this value is greater than 1,000,000, it will be Unlimited.
    </Tooltip>
  );

  return (
    <>
      {/* {value > threshold ? "Unlimited" : value} */}
      {value > threshold && (
        <OverlayTrigger placement="top" overlay={renderTooltip}>
          <i
            className="fa fa-info-circle p-1"
            style={{ fontSize: "20px", color: "#042e77" }}
          ></i>
        </OverlayTrigger>
      )}
    </>
  );
};

export default UnlimitedIndicator;
