import React, { useState } from "react";
import { Badge, Card, Spinner, Table } from "react-bootstrap";
import useFetch from "../components/CoustomHooks/useFetch";
import { GET_LOYALTY_PROGRAM } from "../../services/User/UserApiEndPoints";
import Pagination from "../common/Pagination";

export default function LoyaltyProgramList(props) {
  const [page, setPage] = useState(0);
  const limit = 10;
  const { data, loading, error } = useFetch(
    GET_LOYALTY_PROGRAM,
    "",
    page,
    limit
  );

  //=====================Pagination =================
  const totalItems = data?.total;
  const totalPages = Math.ceil(totalItems / limit);

  return (
    <div>
      <h3 className="m-0 pb-4">Loyalty Programs</h3>

      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <Card className="store-card ">
          <Card.Body className="p-0">
            <Table responsive>
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>Vendor Name</strong>
                  </th>
                  <th>
                    <strong>Store Name</strong>
                  </th>
                  <th>
                    <strong>program name</strong>
                  </th>
                  <th>
                    <strong>title</strong>
                  </th>
                  <th>
                    <strong>description</strong>
                  </th>
                  <th>
                    <strong>Status</strong>
                  </th>
                </tr>
              </thead>

              <tbody>
                {data?.programs?.map((item, i) => (
                  <tr
                    key={i}
                    className={item.isDeleted ? "disabled-row" : ""}
                    onClick={() =>
                      props.history.push({
                        pathname: "./loyalty-offers",
                        state: {
                          storeId: item?.store_id,
                          programId: item?.id,
                        },
                      })
                    }
                  >
                    <td>{item?.store?.User?.name}</td>

                    <td>{item?.store?.storeName}</td>
                    <td>{item?.name}</td>
                    <td>{item?.title}</td>
                    <td>{item?.description}</td>
                    <td>
                      {item?.isDeleted ? (
                        <Badge variant="danger light">Deleted</Badge>
                      ) : (
                        <Badge variant="success light">Active</Badge>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
          {data?.total === 0 && !loading && (
            <div className="justify-content-center d-flex my-5 ">
              Sorry, Data Not Found!
            </div>
          )}
          {data?.total !== 0 && !loading && (
            <div className="d-flex justify-content-between align-items-center p-3 pl-4">
              <div className="dataTables_info">
                Total Programs
                <span
                  style={{
                    color: "#042e77",
                    border: "1px solid #042e77",
                    padding: "5px 15px",
                    borderRadius: "5px",
                    marginLeft: "5px",
                  }}
                >
                  {data?.total}
                </span>
              </div>
              <Pagination
                pageCount={totalPages}
                pageValue={page}
                setPage={setPage}
              />
            </div>
          )}
        </Card>
      )}
    </div>
  );
}
