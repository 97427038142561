import React, { useState } from "react";
import { addUserPointsApi } from "../../services/User/UserService";
import { Modal, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";

export default function AddUserPoints({ show, onHide, userData, refresh }) {
  const [loader, setLoader] = useState(false);
  const [points, setPoints] = useState("");
  const [pointsError, setPointsError] = useState("");
  const [comment, setComment] = useState("");
  const [status, setStatus] = useState("");
  const [commentError, setCommentError] = useState("");
  const [isAffiliate, setIsAffiliate] = useState(false);

  async function handleAddPoints() {
    if (points.trim() === "") {
      setPointsError("Points cannot be empty");
      return;
    } else if (isNaN(points.trim()) || parseFloat(points.trim()) <= 0) {
      setPointsError("Points should be greater than zero");
      return;
    }
    if (comment.trim() === "") {
      setCommentError("Comment cannot be empty");
      return;
    }
    setLoader(true);
    try {
      const response = await addUserPointsApi(
        userData?.id,
        points,
        comment,
        status,
        isAffiliate
      );
      toast.success("Points added successfully");
      onHide();
      refresh();
      setStatus("");
      setIsAffiliate(false); // Reset checkbox after successful operation
    } catch (e) {
      toast.error(e?.response?.data?.data);
    } finally {
      setLoader(false);
    }
  }

  return (
    <Modal show={show} onHide={onHide} centered className="p-3">
      <div className="p-3">
        <h3>Add User Points</h3>
        <div className="p-2">
          <input
            type="number"
            placeholder="Add points"
            className="form-control"
            onChange={(e) => {
              setPoints(e.target.value);
              setPointsError("");
            }}
          />
          {pointsError && <span className="text-danger">{pointsError}</span>}
        </div>
        <div className="p-2">
          <input
            type="text"
            placeholder="Enter the comment"
            className="form-control"
            onChange={(e) => {
              setComment(e.target.value);
              setCommentError("");
            }}
          />
          {commentError && <span className="text-danger">{commentError}</span>}
        </div>
        <div className="p-2">
          <select
            className="form-control"
            value={status}
            required={true}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="" disabled>
              Select status...
            </option>
            <option value="Pending">Pending</option>
            <option value="Confirm">Confirmed</option>
          </select>
        </div>
        {/* Checkbox for isAffiliate */}
        <div className="pl-2 pb-2 checkbox-label-container">
          <input
            type="checkbox"
            id="isAffiliateCheckbox"
            checked={isAffiliate}
            onChange={(e) => setIsAffiliate(e.target.checked)}
          />
          <label htmlFor="isAffiliateCheckbox">Affiliate</label>
        </div>

        <div className="d-flex justify-content-center p-2">
          <button
            type="button"
            className="btn btn-primary btn-sm w-100"
            onClick={() => handleAddPoints()}
          >
            {loader ? <Spinner animation="border" size="sm" /> : "Add Points"}
          </button>
        </div>
      </div>
    </Modal>
  );
}
