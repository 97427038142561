import React, { useEffect, useState } from "react";
import { Modal, OverlayTrigger, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import {
  addSpinWheelApi,
  editWheelOfferApi,
} from "../../services/Vendor Services/VendorService";
import Swal from "sweetalert2";
import MultiSelectDropdown from "../components/MultiSellectDropdown";
import UnlimitedIndicator from "../components/UnlimitedIndecator";

const AddSpinWheelOffers = (props) => {
  const options = [
    // { label: "All", value: "all" },
    { label: "Silver", value: "silver" },
    { label: "Gold", value: "gold" },
    { label: "Platinum", value: "platinum" },
    { label: "Diamond", value: "diamond" },
  ];
  const { title, successMessage, selectedItem } = props.location.state;
  const [wheelPrizes, setWheelPrizes] = useState(
    Array(6)
      .fill(null)
      .map(() => ({ prize: "", isPrize: false, quantity: 0 }))
  );
  const [name, setName] = useState("");
  const [loader, setLoader] = useState(false);
  const [totalNumberSpin, setTotalNumberSpin] = useState("");
  const [pointsRequired, setPointsRequired] = useState("");
  const [timeAllowPerUser, setTimeAllowPerUser] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [tiers, setTiers] = useState([]);

  const specifyLoyaltyTierValues = tiers?.map((tier) => tier?.value);

  const handlePrizeChange = (index, value) => {
    const isPrize = value === "Prize";
    const newPrizes = [...wheelPrizes];

    newPrizes[index] = {
      ...newPrizes[index],
      prize: isPrize ? "" : "Better Luck Next Time",
      isPrize,
      quantity: isPrize ? newPrizes[index].quantity : 0,
    };
    setWheelPrizes(newPrizes);

    if (errors[`prizeName${index}`]) {
      const newErrors = { ...errors };
      delete newErrors[`prizeName${index}`];
      setErrors(newErrors);
    }
  };
  console.log(wheelPrizes);

  const handlePrizeInputChange = (index, value) => {
    const newPrizes = [...wheelPrizes];
    newPrizes[index] = {
      ...newPrizes[index],
      prize: value,
    };
    setWheelPrizes(newPrizes);

    if (errors[`prizeName${index}`]) {
      const newErrors = { ...errors };
      delete newErrors[`prizeName${index}`];
      setErrors(newErrors);
    }
  };
  const handleQuantityChange = (index, value) => {
    const newPrizes = [...wheelPrizes];
    const quantity = Math.max(0, Number(value));
    newPrizes[index].quantity = quantity;
    setWheelPrizes(newPrizes);
  };

  const handleChange =
    (setter, field, isNumber = false) =>
    (e) => {
      const value = isNumber ? Number(e.target.value) : e.target.value;
      setter(value);
      if (errors[field]) {
        const newErrors = { ...errors };
        delete newErrors[field];
        setErrors(newErrors);
      }
    };

  console.log(tiers);
  const validateForm = () => {
    const newErrors = {};

    if (!name) {
      newErrors.name = "Name is required";
    }

    wheelPrizes.forEach((prize, index) => {
      if (prize.isPrize && !prize.prize) {
        newErrors[`prizeName${index}`] = "Prize is required";
      }
      if (prize.isPrize && !isNaN(prize.prize)) {
        let formattedPrize = prize.prize;
        if (formattedPrize.startsWith(".")) {
          formattedPrize = "0" + formattedPrize;
          prize.prize = formattedPrize;
        }
        if (Number(prize.prize) <= 0) {
          newErrors[`prizeName${index}`] = "Prize must be greater than zero.";
        }
      }
    });

    if (
      !totalNumberSpin ||
      isNaN(totalNumberSpin) ||
      Number(totalNumberSpin) <= 0
    ) {
      newErrors.totalNumberSpin =
        "Total number of spins must be a positive number";
    }
    if (
      pointsRequired !== "" &&
      (isNaN(pointsRequired) || Number(pointsRequired) < 0)
    ) {
      newErrors.pointsRequired =
        "Points required for spin must be a non-negative number";
    }
    if (
      !timeAllowPerUser ||
      isNaN(timeAllowPerUser) ||
      Number(timeAllowPerUser) <= 0
    ) {
      newErrors.timeAllowPerUser =
        "Times allowed per user must be a positive number";
    }
    if (Number(timeAllowPerUser) > Number(totalNumberSpin)) {
      newErrors.timeAllowPerUser =
        "Times allowed per user cannot be greater than the total number of spins";
    }
    if (!startTime) {
      newErrors.startTime = "Start time is required";
    }
    if (!endTime) {
      newErrors.endTime = "End time is required";
    }
    if (!message) {
      newErrors.message = "Message is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const formatDate = (isoDate) => {
    return isoDate ? isoDate.slice(0, 10) : "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const showAlert = title === "Edit Wheel Contest";
    let proceed = true;

    if (showAlert) {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "Do you want to update this contest?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, update it!",
        cancelButtonText: "No, cancel!",
      });

      proceed = result.isConfirmed;
    }
    console.log(wheelPrizes);

    if (proceed) {
      setLoader(true);
      try {
        const postData = {
          wheelPrizes,
          name,
          specifyLoyaltyTier: specifyLoyaltyTierValues,
          totalNumberSpin,
          timeAllowPerUser,
          pointsRequired,
          startTime,
          endTime,
          message,
        };

        if (title === "Edit Wheel Contest") {
          postData.contestId = selectedItem.id;
        }

        const response =
          title === "Edit Wheel Contest"
            ? await editWheelOfferApi(postData)
            : await addSpinWheelApi(postData);

        props?.history.push("/spin-wheel-management");
        toast.success(successMessage);
      } catch (e) {
        toast.error(
          e?.response?.data?.data
            ? e?.response?.data?.data
            : e?.response?.data?.message
        );
      } finally {
        setLoader(false);
      }
    } else {
      props?.history.push("/spin-wheel-management");
    }
  };

  useEffect(() => {
    if (selectedItem) {
      setWheelPrizes(
        selectedItem?.wheelPrizes ||
          Array(6)
            .fill(null)
            .map(() => ({ prize: "", isPrize: false }))
      );
      const prefilledTiers = selectedItem.specifyLoyaltyTier
        .map((value) => options.find((option) => option.value === value))
        .filter(Boolean);
      setTiers(prefilledTiers);
      setName(selectedItem?.name || "");
      setTotalNumberSpin(selectedItem?.totalNumberSpin || "");
      setTimeAllowPerUser(selectedItem?.timeAllowPerUser || "");
      setStartTime(formatDate(selectedItem?.startTime) || "");
      setPointsRequired(selectedItem?.pointsRequired || "");
      setEndTime(formatDate(selectedItem?.endTime) || "");
      setMessage(selectedItem?.message || "");
    }
  }, [selectedItem]);

  return (
    <div>
      <h3 className="pl-4">{title}</h3>
      <form onSubmit={handleSubmit} className="p-4">
        <div>
          <div className="form-group">
            <label>Contest Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter name of the spin"
              value={name}
              onChange={handleChange(setName, "name")}
            />
            {errors.name && <p style={{ color: "red" }}>{errors.name}</p>}
          </div>
          <div className="form-group">
            <label>Start Date</label>
            <input
              type="date"
              className="form-control"
              placeholder="Start date"
              value={startTime}
              onChange={handleChange(setStartTime, "startTime")}
            />
            {errors.startTime && (
              <p style={{ color: "red" }}>{errors.startTime}</p>
            )}
          </div>
          <div className="form-group">
            <label>End Date</label>
            <input
              type="date"
              className="form-control"
              placeholder="End date"
              value={endTime}
              onChange={handleChange(setEndTime, "endTime")}
            />
            {errors.endTime && <p style={{ color: "red" }}>{errors.endTime}</p>}
          </div>
          <div className="form-group">
            <label>Specify General Loyalty Tier</label>

            <MultiSelectDropdown
              options={options}
              selected={tiers}
              setSelected={setTiers}
            />
          </div>

          <div className="form-group">
            <label>Total Number of Spins</label>
            <input
              type="number"
              className="form-control"
              placeholder="Enter total number of spins"
              value={totalNumberSpin}
              onChange={handleChange(
                setTotalNumberSpin,
                "totalNumberSpin",
                true
              )}
            />
            {errors.totalNumberSpin && (
              <p style={{ color: "red" }}>{errors.totalNumberSpin}</p>
            )}
          </div>
          <div className="form-group">
            <label>Points Required For Spin</label>
            <input
              type="number"
              className="form-control"
              placeholder="Enter points"
              value={pointsRequired}
              onChange={handleChange(setPointsRequired, "pointsRequired", true)}
            />
            {errors.pointsRequired && (
              <p style={{ color: "red" }}>{errors.pointsRequired}</p>
            )}
          </div>

          <div className="form-group">
            <label>Times Allowed Per User</label>
            <input
              type="number"
              className="form-control"
              placeholder="Enter times allowed"
              value={timeAllowPerUser}
              onChange={handleChange(
                setTimeAllowPerUser,
                "timeAllowPerUser",
                true
              )}
            />
            {errors.timeAllowPerUser && (
              <p style={{ color: "red" }}>{errors.timeAllowPerUser}</p>
            )}
          </div>

          <h4 className="mt-4">Spin Wheel Prizes</h4>
          {/* <div>
            {wheelPrizes.map((prize, index) => (
              <div>
                <div
                  className="form-group d-flex align-items-center"
                  key={index}
                  style={{ gap: "10px" }}
                >
                  <select
                    className="form-control"
                    value={
                      prize.isPrize
                        ? "Prize"
                        : prize.prize === ""
                        ? ""
                        : "Better Luck Next Time"
                    }
                    onChange={(e) => handlePrizeChange(index, e.target.value)}
                  >
                    <option value="" disabled>
                      Select prizes
                    </option>
                    <option value="Prize">Prize</option>
                    <option value="Better Luck Next Time">
                      Better Luck Next Time
                    </option>
                  </select>
                  {prize.isPrize && (
                    <input
                      type="number"
                      className="form-control"
                      value={prize.prize}
                      onChange={(e) =>
                        handlePrizeInputChange(index, e.target.value)
                      }
                      placeholder={`Enter prize ${index + 1} name`}
                    />
                  )}
                </div>
                {errors[`prizeName${index}`] && (
                  <p style={{ color: "red" }}>{errors[`prizeName${index}`]}</p>
                )}
              </div>
            ))}
          </div> */}
          <div>
            {wheelPrizes.map((prize, index) => (
              <div key={index}>
                <div
                  className="form-group d-flex align-items-center"
                  style={{ gap: "10px" }}
                >
                  <select
                    className="form-control"
                    value={
                      prize.isPrize
                        ? "Prize"
                        : prize.prize === ""
                        ? ""
                        : "Better Luck Next Time"
                    }
                    onChange={(e) => handlePrizeChange(index, e.target.value)}
                  >
                    <option value="" disabled>
                      Select prize
                    </option>
                    <option value="Prize">Prize</option>
                    <option value="Better Luck Next Time">
                      Better Luck Next Time
                    </option>
                  </select>
                  {prize.isPrize && (
                    <div
                      className="d-flex align-items-center w-100"
                      style={{ gap: "10px" }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        value={prize.prize}
                        onChange={(e) =>
                          handlePrizeInputChange(index, e.target.value)
                        }
                        placeholder="Enter prize"
                      />
                      <input
                        type="number"
                        className="form-control"
                        value={prize.quantity}
                        onChange={(e) =>
                          handleQuantityChange(index, e.target.value)
                        }
                        placeholder="Enter quantity"
                        min="0"
                      />
                    </div>
                  )}
                </div>
                {errors[`prizeName${index}`] && (
                  <p style={{ color: "red" }}>{errors[`prizeName${index}`]}</p>
                )}
              </div>
            ))}
          </div>

          <div className="form-group">
            <label>Message</label>
            <textarea
              style={{ minHeight: "100px" }}
              className="form-control"
              placeholder="Enter message"
              value={message}
              onChange={handleChange(setMessage, "message")}
            />
            {errors.message && <p style={{ color: "red" }}>{errors.message}</p>}
          </div>
          <div className="d-flex justify-content-end">
            <button type="submit" className="btn btn-primary btn-sm mt-3">
              {loader ? <Spinner animation="border" size="sm" /> : title}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddSpinWheelOffers;
